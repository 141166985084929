import React from 'react'
import { Action, Box, Subheader } from '@revolut/ui-kit'
import { PreviewBody } from '@src/pages/Forms/InterviewScorecardTemplate/Preview/Preview'
import { ScorecardFormWrapper } from './ScorecardForm'
import { FormattedMessage } from 'react-intl'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

type PreviewScorecardProps = {
  id: number
}

export const PreviewScorecard = ({ id }: PreviewScorecardProps) => {
  return (
    <Box mt="s-32">
      <Subheader>
        <Subheader.Title>
          <FormattedMessage
            id="onboarding.interviews.roundSidebar.scorecard.title"
            defaultMessage="Interview scorecard"
          />
        </Subheader.Title>
        <Subheader.Side>
          <Action
            use="a"
            href={`${pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS, {
              id,
            })}?ref=onboarding`}
            target="_blank"
          >
            <FormattedMessage
              id="onboarding.interviews.roundSidebar.scorecard.edit"
              defaultMessage="Edit scorecard"
            />
          </Action>
        </Subheader.Side>
      </Subheader>
      <ScorecardFormWrapper id={id}>
        <PreviewBody id={id} hideSettingsButtons />
      </ScorecardFormWrapper>
    </Box>
  )
}
