import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, useStatusPopup, StatusPopup, chain } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateTo } from '@src/actions/RouterActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ConfirmationDialogInterface } from '@src/features/Popups/ConfirmationDialog'
import {
  completeOnboardingCheckpoint,
  updateOnboardingCheckpointCategoryCurrentStep,
  useInvalidateOnboardingCheckpoints,
} from '@src/api/onboardingChecklistV2'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { OnboardingCheckpointConfig } from '../common/checkpointsConfig'
import { useNextConfig } from '../common/hooks'

type OnboardingActionsProps = {
  config: OnboardingCheckpointConfig
  confirmationDialogue?: Partial<ConfirmationDialogInterface>
  currentStep: string
  disableNext: boolean
  isForm: boolean
  isLastStep: boolean
  isLastCheckpoint?: boolean
  nextRoute: string
  pendingNext: boolean
  updateSteps: boolean
  onBeforeSubmit?: () => Promise<unknown>
  nextButtonLabel?: string
}

const OnboardingActions = ({
  config,
  confirmationDialogue,
  currentStep,
  disableNext,
  isForm,
  isLastStep,
  isLastCheckpoint,
  nextRoute,
  pendingNext,
  updateSteps,
  onBeforeSubmit,
  nextButtonLabel = 'Next',
}: OnboardingActionsProps) => {
  const nextConfig = useNextConfig(config)

  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()
  const navigateToPath = (path: string | null) => {
    navigateTo(pathToUrl(path ?? ROUTES.ONBOARDING_CHECKLIST_V2.ALL))
  }
  const invalidateOnboardingCheckpoints = useInvalidateOnboardingCheckpoints()

  const showSuccess = () => {
    statusPopup.show(
      <StatusPopup variant="success-result" onClose={statusPopup.hide}>
        <StatusPopup.Title>{config.popup.title || 'Completed'}</StatusPopup.Title>
        <StatusPopup.Description>{config.popup.description}</StatusPopup.Description>
        <StatusPopup.Actions>
          {nextConfig ? (
            <Button
              elevated
              onClick={() => {
                statusPopup.hide()
                navigateToPath(nextConfig.path)
              }}
              data-event-key="onboarding.general.nextOnboardingStepButton"
            >
              {chain(
                formatMessage({
                  id: 'onboarding.general.nextOnboardingStepButton',
                  defaultMessage: 'Next',
                }),
                nextConfig.title,
              )}
            </Button>
          ) : null}
          <Button
            variant="secondary"
            onClick={() => {
              statusPopup.hide()
              navigateTo(ROUTES.ONBOARDING_CHECKLIST_V2.ALL)
            }}
          >
            {formatMessage({
              id: 'onboardingV2.general.goToDashboard',
              defaultMessage: 'Go to Dashboard',
            })}
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const navigateToNext = async () => {
    setLoading(true)
    try {
      if (updateSteps) {
        await updateOnboardingCheckpointCategoryCurrentStep(config.category, currentStep)
      }
      if (onBeforeSubmit) {
        await onBeforeSubmit()
      }
      if (isLastStep) {
        await completeOnboardingCheckpoint(config.category)

        if (isLastCheckpoint) {
          navigateToPath(ROUTES.ONBOARDING_CHECKLIST_V2.ALL)
        } else {
          showSuccess()
        }
      } else {
        navigateToPath(nextRoute ?? nextConfig?.path)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      if (updateSteps || isLastStep) {
        invalidateOnboardingCheckpoints()
      }
    }
  }

  return (
    <PageActions>
      {isForm ? (
        <NewSaveButtonWithPopup
          noPopup
          onAfterSubmit={navigateToNext}
          hideWhenNoChanges={false}
          disabled={disableNext}
          pending={pendingNext || loading}
          confirmationDialogue={confirmationDialogue}
          useValidator
          data-event-key="onboarding.general.nextOnboardingStepButton"
        >
          {nextButtonLabel}
        </NewSaveButtonWithPopup>
      ) : (
        <Button
          onClick={navigateToNext}
          disabled={disableNext}
          pending={pendingNext || loading}
          data-event-key="onboarding.general.nextOnboardingStepButton"
        >
          {nextButtonLabel}
        </Button>
      )}
    </PageActions>
  )
}

export default OnboardingActions
